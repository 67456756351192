import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import addEditTransferAsset from '../transferAsset/addEditTransferAsset';
import batchDetails from '../transferAsset/batchDetails';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
import batchDetailsRma from './batchDetailsRma';
export default {
  name: 'TransferAsset',
  components: {
    DatePicker,
    addEditTransferAsset,
    batchDetails,
    batchDetailsRma
  },
  props: ['currentLoc','locAccessFlag'],
  watch: {
    currentPage: function() {
      this.getTransferAssetsList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTransferAssetsList();
    }
  },
  data() {
    return {
      showBatchDetailsModal: false,
      showAddTransferAssetModal: false,
      payload: null,
      transferDate: [],
      loader: false,
      batchNo: '',
      description: '',
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: {
        text: "Delhivery Limited",
        value: "67"
      },
      bookType: {
        text: "DPL TPT FA1",
        value: "DPL TPT FA1"
      },
      fromLocation: {
        text: null,
        value: null
      },
      toLocation: {
        text: null,
        value: null
      },
      batchStatus: {
        text: null,
        value: null
      },
      invoiceNum: '',
      transferAssetData: [],
      transferAssetFields: [
        {
          key: 'batch_num',
          label: 'Batch No.'
        },
        {
          key: 'invoice_number',
          label: 'Invoice No.'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'book_type_code',
          label: 'Book Type'
        },
        {
          key: 'location_from'
        },
        {
          key: 'location_to'
        },
        {
          key: 'transfer_date'
        },
        {
          key: 'transfer_type'
        },
        {
          key: 'received_date'
        },
        {
          key: 'batch_status_meaning',
          label: 'Status'
        },
        {
          key: 'period'
        },
        {
          key: 'from_gstn_no',
          label: 'GSTN From'
        },
        {
          key: 'to_gstn_no',
          label: 'GSTN To'
        },
        {
          key: 'created_by_name',
          label: 'Created By'
        }
      ],
      batchData: null,
      locType: null,
      batchType: null,
      searchParams: {},
      routeName: this.$router.currentRoute.name,
      transferAssetViaCreateBatchData: null,
      accessButtons:{}
    };
  },
  validations: {
    bookType: {
      text: {
        required
      }
    },
    legalEntity: {
      text: {
        required
      }
    },
    createBatchFlag: false
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.showBatchDetailsModal) {
          this.searchParams = {
            legalEntity: this.legalEntity,
            bookType: this.bookType,
            fromLocation: this.fromLocation,
            toLocation: this.toLocation
          }
          this.showHideTransferAssetModal(true)
        }
        if (actionName === 'download' && !this.showBatchDetailsModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'assets/getTransferAssets',
            'transfer-asset',
            () => (this.loader = false)
          );
        }
      }
    });
    if(this.currentLoc) {
      this.fromLocation = this.currentLoc;
      this.getTransferAssetsList(true);
    }
    if(this.routeName === 'LocDash') {
      this.batchType = 'IT';
    }
  },
  methods: {
    getTransferAssetsList(flag) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          book_type_code: this.bookType.value,
          loaction_from: this.fromLocation.value,
          loaction_to: this.toLocation.value,
          batch_status: this.batchStatus.value,
          batch_num: this.batchNo,
          invoice_num: this.invoiceNum,
          transfer_date_from: commonHelper.formattedDate(this.transferDate[0]),
          transfer_date_to: commonHelper.formattedDate(this.transferDate[1]),
          batch_type: this.routeName === 'LocDash' ? 'IT' : 'NON-IT'
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getTransferAssets', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.transferAssetData = resp.data.data.page;
              this.transferAssetViaCreateBatchData = resp.data.data.page;
              if(flag&&this.transferAssetViaCreateBatchData&&this.createBatchFlag) {
                   this.showHideBatchDetailsModal(true, this.transferAssetViaCreateBatchData[0])
              }
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    openValueSetModal(vsetCode) {
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.bookType = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookType = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.LOCATION:
          if (this.locType === 'from_loc') {
            this.fromLocation = {
              text: item.value_code,
              value: item.value_set_dtl_id
            };
            this.locType = null;
          } else if (this.locType === 'to_loc') {
            this.toLocation = {
              text: item.value_code,
              value: item.value_set_dtl_id
            };
            this.locType = null;
          }
          break;
        case appStrings.VALUESETTYPE.ASSET_TRANSFER_BATCH_STATUS:
          this.batchStatus = {
            value: item.value_meaning,
            text: item.value_code
          };
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    mainSearch() {
      this.getTransferAssetsList();
    },
    clear() {
      this.legalEntity = {
        text: null,
        value: null
      };
      this.bookType = {
        text: null,
        value: null
      };
      if(!this.currentLoc){
      this.fromLocation = {
        text: null,
        value: null
      };
      this.toLocation = {
        text: null,
        value: null
      };
    }
      this.batchStatus = {
        text: null,
        value: null
      };
      this.invoiceNum = '';
      this.batchNo = '';
      this.description = '';
      this.transferDate = [];
      this.transferAssetData = [];
    },
    showHideTransferAssetModal(flag) {
      this.showAddTransferAssetModal = flag;
      if(flag && !this.locAccessFlag && this.batchType) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        }
      }
    },
    showHideBatchDetailsModal(flag, event) {
      this.showBatchDetailsModal = flag;
      if (flag) {
        this.batchData = event;
        this.transferBatchId = event.trf_batch_id;
      }else{
        this.getTransferAssetsList()
      }
      if(flag && !this.locAccessFlag && this.batchType) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: true,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    chooseValueset(name, locType) {
      this.locType = locType === 'from' ? 'from_loc' : 'to_loc';
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'book_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_BOOK_TYPE);
          break;
        case 'hr_location_from':
        case 'hr_location_to':
          this.openValueSetModal(appStrings.VALUESETTYPE.LOCATION);
          break;
        case 'asset_transfer_batch_status':
          this.openValueSetModal(
            appStrings.VALUESETTYPE.ASSET_TRANSFER_BATCH_STATUS
          );
          break;
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    searchCreatedBatch(batch_no, legal_Entity, book_type, flag) {
      this.batchNo = batch_no;
      this.legalEntity = legal_Entity;
      this.bookType = book_type;
      this.createBatchFlag = flag;
      this.showAddTransferAssetModal = false;
      this.getTransferAssetsList(true);
    },

    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.bookType.value) {
        this.bookType = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.fromLocation.value) {
        this.fromLocation = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.toLocation.value) {
        this.toLocation = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.batchStatus.value) {
        this.batchStatus = {
          text: null,
          value: null
        };
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
